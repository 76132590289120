<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <div>
          <el-form :model='searchCondition' size='mini' inline>
            <el-form-item label='红人'>
              <el-input v-model='searchCondition.nickname' clearable placeholder='红人昵称'
                        @change='handleSearch'></el-input>
            </el-form-item>
            <el-form-item label='分组名称'>
              <el-input v-model='searchCondition.group_name' clearable placeholder='分组名称'
                        @change='handleSearch'></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='handleSearch'>查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class='default-table' style='display: inline-block'>
          <el-table :data='dataList' max-height='600' @sort-change='changeTableSort' border>
            <el-table-column type='index' label='序号' width='60' align='center'></el-table-column>
            <el-table-column label='ID' prop='id' width='60' align='center'></el-table-column>
            <el-table-column prop='group_name' label='部门' width='160' align='center'
                             sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.group_name }}</span>
                <span style='font-weight: 300'>({{ row.group_id }})</span>
              </template>
            </el-table-column>
            <el-table-column prop='nickname' label='红人昵称' width='140' align='center' sortable='custom'>
              <template slot-scope='{row}'>
                <artist-nickname :info='{artist_id:row.artist_id,nickname:row.nickname}' />
              </template>
            </el-table-column>
            <el-table-column prop='sign_at' label='签约时间' width='140' align='center' sortable='custom'>
              <template slot-scope='{row,column}'>
                {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
              </template>
            </el-table-column>
            <el-table-column prop='in_at' label='调入时间' width='140' align='center' sortable='custom'>
              <template slot-scope='{row,column}'>
                {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
              </template>
            </el-table-column>
            <el-table-column prop='fired_at' label='解约时间' width='140' align='center' sortable='custom'>
              <template slot-scope='{row,column}'>
                {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
              </template>
            </el-table-column>
            <el-table-column prop='out_at' label='调出时间' width='140' align='center' sortable='custom'>
              <template slot-scope='{row,column}'>
                {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
              </template>
            </el-table-column>
            <el-table-column label='运营时长' width='160' align='center'>
              <template slot='header'>
                <span>运营时长（天）</span>
                <el-tooltip class='item' effect='dark' content='运营时长=解约时间/调出时间-签约时间-调入时间' placement='right'>
                  <i class='el-icon-question'></i>
                </el-tooltip>
              </template>
              <template slot-scope='{row}'>{{ row.duration || '/' }} 天</template>
            </el-table-column>
            <el-table-column label='备注' prop='remark' show-overflow-tooltip min-width='400'>

            </el-table-column>
          </el-table>
          <div>
            <pagination :total.sync='total' :limit.sync='pageData.page_size'
                        :page.sync='pageData.current_page'
                        @pagination='getList' />
          </div>
        </div>
      </div>
    </page-header-layout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'TeamKolChangeList',
  components: { PageHeaderLayout },
  data() {
    return {
      loading: false,
      dataList: [],
      orderSort: { 'in_at': 'asc', 'nickname': 'desc', 'sign_at': 'desc', 'fired_at': 'desc', 'out_at': 'desc' },
      pageData: { page_size: 15, current_page: 1 },
      total: 0,
      searchCondition: {
        nickname: null,
        dept_id: null,
        dept_name: null
      }
    }
  },
  methods: {
    changeTableSort({ prop, order }) {
      let orderSort = { ...this.orderSort }
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()
    },
    handleSearch() {
      this.orderSort = {}
      this.pageData.current_page = 1
      this.getList()
    },
    async getList() {
      this.dataList = []
      // this.$notify.info(`${this.searchCondition.year}年，组ID：${this.searchCondition.group_id}`)

      this.loading = true
      let {
        list,
        pages
      } = await this.$api.getTeamKoLChangeList(Object.assign(this.searchCondition, this.pageData, { order: this.orderSort }))
      this.$nextTick(() => {
        this.dataList = list || []
        this.total = pages.total || 0
        this.pageData.current_page = pages.current_page || 1
        this.pageData.page_size = pages.page_size || 15
      })

      setTimeout(() => {
        this.loading = false
      }, 500)

    }
  },
  mounted() {
    this.handleSearch()
  }
}
</script>

<style scoped>

</style>